/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Form, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useCommonApi from "../hooks/common-api";
import Loader from "../components/Loader";
import moment from "moment";

function BlogList() {
  const navigate = useNavigate();
  const [search, setsearch] = useState("");
  const [loader, setloader] = useState(false);
  const { featureStatusBlog } = useCommonApi();
  const { state } = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [bloglist, setbloglist] = useState([]);
  console.log("");

  console.log(bloglist);
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) {
      return "";
    }
    return moment(dateTimeString).format("D MMMM, YYYY");
  };
  const onShowMoreClick = async () => {
    setloader(true);
    let offset = blogListTotal?.length;
    let response = await getBlogsListGraphql(10, offset);
    if (response.status !== 200) {
      setloader(false);
      return;
    }
    setloader(false);
    let newBlogs = response?.data?.blogs || [];
    setblogListTotal([...blogListTotal, ...newBlogs]);
    setpageInfo(response?.data?.pageInfo);
  };
  const getBlogsListGraphql = async (limit = 10, offset = 0) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/graphql`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        timeout: 10000,
        data: {
          query: `
            query($limit: Int!, $offset: Int!){
              getblogs(limit: $limit, offset: $offset) {
                blogs {
                  id,
                  adm_user_id,
                  blog_title,
                  blog_content,
                  blog_image,
                  status,
                  featured,
                  author_name,
                  blog_slug,
                  blog_meta_title,
                  blog_meta_description,
                  publish_date,
                  category {
                    blog_cat_id,
                    cat_name,
                    cat_icon
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                  total
                }
              }
            }
          `,
          variables: { limit, offset },
        },
      });

      const response_data = response?.data?.data?.getblogs;
      return {
        status: 200,
        data: response_data,
      };
    } catch (error) {
      console.log(error);
      return {
        status: 400,
        data: null,
      };
    }
  };
  const [blogListTotal, setblogListTotal] = useState([]);
  const [pageInfo, setpageInfo] = useState({});
  const getBlogListFn = async () => {
    setloader(true);
    let offset = blogListTotal?.length;
    const response = await getBlogsListGraphql(10, offset);
    if (response.status !== 200) {
      setloader(false);
      return;
    }
    // if (response?.length > 0) {
    //   setbloglist(response);
    // }
    let newBlogs = response?.data?.blogs || [];
    setblogListTotal([...blogListTotal, ...newBlogs]);
    setpageInfo(response?.data?.pageInfo);
    setloader(false);
  };
  const getPublishDate = (item) => {
    const formattedDate = item?.publish_date
      ? new Date(item?.publish_date).toISOString().split("T")[0]
      : "";
    return formattedDate;
  };
  useEffect(() => {
    getBlogListFn();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">Blog List</h2>
            <h6 className="py-1">
              Total Blogs: {pageInfo?.total || blogListTotal?.length}
            </h6>
            <div className="d-flex py-4">
              <div>
                <input
                  type="search"
                  class="form-control"
                  id="searchinput"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
              </div>
              <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn py-2 d-flex align-items-center"
                  onClick={() => {
                    localStorage.setItem(
                      "admin_selected_blog",
                      JSON.stringify({})
                    );
                    navigate("/blog/create/new", { state });
                  }}
                >
                  <svg className="icon fs-20 me-1">
                    <use href="#icon_plus"></use>
                  </svg>
                  Create Blog
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Serial Number</th>
                <th>Blog Title</th>
                <th>Publish Date</th>
                <th>Blog Meta Title</th>
                <th>Blog Meta Description</th>
                <th>Writer Name</th>
                <th>Status</th>
                <th>Featured</th>
                <th></th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {blogListTotal
                ?.filter((item) =>
                  item?.blog_title?.toLowerCase().includes(search.toLowerCase())
                )
                ?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.blog_title}</td>
                    <td>{formatDateTime(item?.publish_date)}</td>
                    <td>{item?.blog_meta_title}</td>
                    <td>{item?.blog_meta_description}</td>
                    <td>{item?.author_name}</td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitch1"
                          onChange={(e) => {
                            setblogListTotal((prev) =>
                              prev?.map((i) => {
                                if (i?.id === item?.id) {
                                  i.status = e.target.checked;
                                }
                                return i;
                              })
                            );
                            featureStatusBlog({
                              id: item?.id,
                              status: e.target.checked ? "1" : "0",
                              act: "status",
                            });
                          }}
                          checked={item?.status}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitch1"
                          onChange={(e) => {
                            setblogListTotal((prev) =>
                              prev?.map((i) => {
                                if (i?.id === item?.id) {
                                  i.featured = e.target.checked;
                                }
                                return i;
                              })
                            );
                            featureStatusBlog({
                              id: item?.id,
                              featured: e.target.checked ? "1" : "0",
                              act: "featured",
                            });
                          }}
                          checked={item?.featured}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className="d-flex align-items-center"
                        onClick={() => {
                          localStorage.setItem(
                            "admin_selected_blog",
                            JSON.stringify(item)
                          );
                          navigate(`/blog/create/${item?.id}`, { state });
                        }}
                      >
                        <button type="button" className="arrow-btn mx-auto">
                          <svg className="icon fs-24">
                            <use href="#icon_edit"></use>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </table>
          </Col>
        </Row>
      </Container>
      <Container>
          <Row>
            <Col className=" col-12 text-center pt-3 pb-5">
              {pageInfo?.hasNextPage && (
                <button
                  type="button"
                  className="grd-btn py-2 d-flex align-items-center mx-auto"
                  disabled={loader}
                  onClick={() => onShowMoreClick()}
                >
                  {loader ? <Loader /> : "Show More"}
                </button>
              )}
            </Col>
          </Row>
        </Container>
      {/*  Modal  */}
      <Modal size="" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Header className="border-0 " closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          <h5 className="fw-600 fs-20 text-center">
            Choose allowed features for ｛Customer Name｝
          </h5>
          <div className="d-flex justify-content-center mt-3">
            <div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheck1"
                />
                <label className="form-check-label" for="flexCheck1">
                  Dashboard
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheck2"
                />
                <label className="form-check-label" for="flexCheck2">
                  Tracking
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheck3"
                />
                <label className="form-check-label" for="flexCheck3">
                  SKU Cost Footprint
                </label>
              </div>
            </div>
          </div>
          <div className="text-center pb-3">
            <button
              typeof="button"
              className="normal-btn px-4 rounded-pill me-4"
            >
              Cancel
            </button>
            <button
              typeof="button"
              className="normal-btn px-4 rounded-pill me-4"
            >
              Grant Access
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BlogList;
